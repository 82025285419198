const navLinks = [
  {
    label: "Hello",
    anchor: "#hello",
  },
  {
    label: "Skills",
    anchor: "#skills",
  },
  {
    label: "Experience",
    anchor: "#experience",
  },
  {
    label: "Education",
    anchor: "#education",
  },
];

export default navLinks;
